<template>

  <b-card>
    <b-row>
      <b-col class="text-left">
        <b-button
            :to="{ name: 'bookings-list' }"
            variant="primary"
            class="mr-2 ml-auto"
            type="submit"
            size="sm"
        >
          {{ $t("Back to Overview") }}
        </b-button>
      </b-col>
    </b-row>

    <hr/>

    <b-row>
      <b-col md="4" class="mt-2">
        <h5 class="d-flex align-items-center">
          <b-badge pill :variant="getStepState(1)" class="mr-1">1</b-badge>
          {{ $t('Please select a Date') }}
        </h5>
        <b-form-group>
          <flat-pickr
              @input="getSlots"
              name="date"
              :placeholder="$t('Please select a Date')"
              v-model="selectedDate"
              class="form-control d-none"
              :config="flatPickrConfig"
          />
        </b-form-group>
      </b-col>

      <b-col class="">
        <h5 class="mt-2 d-flex align-items-center">
          <b-badge pill :variant="getStepState(2)" class="mr-1">2</b-badge>
          {{ $t("Book a Time slot now") }}
        </h5>
        <b-alert
            variant="danger"
            show
            v-if="selectedDate == ''"
        >
          <div class="alert-body">
            {{ $t('Please chose a date') }}
          </div>
        </b-alert>
        <b-alert
            variant="danger"
            show
            v-if="selectedDate != '' && slots.length == 0 && slotsLoaded"
        >
          <div class="alert-body">
            {{ $t('No time slots available') }}
          </div>
        </b-alert>

        <ul class="list-inline">
          <li class="list-inline-item" v-for="(slot, key) in slots">
            <b-button
                v-model="selectedTimeSlot"
                :variant="activeSlot == key ? 'success' : 'primary'" class="mt-1"
                type="submit"
                size="sm"
                @click="slotRequest(slot); activeSlot = key"
            > {{ slot.start_time }} - {{ slot.end_time }}
            </b-button>
          </li>
        </ul>
      </b-col>
      <b-col>
        <h5 class="mt-2 d-flex align-items-center">
          <b-badge pill :variant="getStepState(3)" class="mr-1">3</b-badge>
          {{ $t("Complete your booking") }}
        </h5>
        <b-alert variant="danger" show v-if="selectedDate == ''">
          <div class="alert-body">
            {{ $t('Please select a date to complete your booking') }}
          </div>
        </b-alert>
        <b-alert variant="danger" show v-else-if="selectedDate != ''  && !slotSelected">
          <div class="alert-body">
            {{ $t('Please select a time slot to complete your booking') }}
          </div>
        </b-alert>

        <div class="booking-details">
          <ul class="list-unstyled">
            <li class="mb-1">
              <feather-icon icon="CalendarIcon" />
              <span>{{ selectedDate }}</span></li>
            <li class="mb-1">
              <feather-icon icon="ClockIcon" />
              <span v-if="selectedDate">{{ selectedTimeSlot.start_time }} - {{ selectedTimeSlot.end_time }} </span>
              <span v-else>{{ $t("No date selected") }}</span>
            </li>
            <li class="mb-1">
              <feather-icon icon="DollarSignIcon" />
              <span v-if="selectedTimeSlot"></span>
            </li>
            <li>
              <b-button
                  class="mt-2"
                  variant="success"
                  type="submit"
                  size="md"
              > {{ $t('send request to Expert') }}
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {BRow, BCol, BFormGroup, BCard, BTable, BButton, BFormDatepicker, BAlert, BBadge} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from "vue-ripple-directive";
import {German} from 'flatpickr/dist/l10n/de';

export default {

  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BFormDatepicker,
    flatPickr,
    BFormGroup,
    BAlert,
    BBadge,
  },

  data() {
    return {
      selectedDate: "",
      selectedTimeSlot: "",
      button: {
        requestbutton: "",
      },
      flatPickrConfig: {
        dateFormat: 'Y-m-d',
        disable: [{
          from: '01-01-0000',
          to: 'today'
        }],
        inline: true,
        locale: German,
        altInputClass: "d-none"
      },
      slots: [],
      slotsLoaded: false,
      slotSelected: false,
      activeSlot: "-1",
      expert: {},
      directives: {
        Ripple,
      },
      steps: {
        step1: false,
        step2: false,
        step3: false,
      }
    }

  },
  computed: {},
  mounted() {
    this.dateSelected()
    console.debug(German)
  },

  methods: {
    dateSelected() {
    },
    getExpert() {
      let url = `/client/experts/${this.$route.params.expertid}`

      this.$http.get(url)
          .then(response => {
            this.expert = response.data
          })
          .catch(error => {
          })
    },
    getEvent() {
      let url = `/client/experts/${this.$route.params.expertid}/events/${this.$route.params.eventid}`

      this.$http.get(url)
          .then(response => {
            this.expert = response.data
          })
          .catch(error => {
          })
    },
    getSlots() {
      this.steps.step1 = true

      if (this.selectedDate !== "") {
        this.slotsLoaded = false
        let url = `/client/booking/expert/${this.$route.params.expertid}/event/${this.$route.params.eventid}/slots`
        this.$http.post(url, {date: this.selectedDate})
            .then(response => {
              this.slots = response.data
              this.slotsLoaded = true
            })
            .catch(error => {
            })
      }
    },

    slotRequest(slot) {
      this.slotSelected = true
      this.steps.step2 = true
      this.selectedTimeSlot = slot

      return
      if (this.slotRequest) {
        let url = `/client/booking/expert/${this.$route.params.expertid}/event/${this.$route.params.eventid}`
        let data = {
          start_time: slot.start_time,
          end_time: slot.end_time,
          date: this.date.selecteddate
        }
        this.$http.post(url, data)
            .then(response => {
              this.$bvToast.toast(this.$i18n.t('Item saved'), {
                title: this.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right'
              })
              this.slotrequest = response.data
            })
            .catch(error => {
            })
      }
    },
    getStepState(step) {
      if (step == 1) {
        if (this.steps.step1) return 'success'

        return 'warning'
      }
      if (step == 2) {
        if (this.steps.step1 && !this.steps.step2) return 'warning'

        if (this.steps.step2) return 'success'

        return 'primary'
      }
      if (step == 3) {
        if (this.steps.step2 && !this.steps.step3) return 'warning'

        if (this.steps.step3) return 'success'

        return 'primary'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
